<template>
  <div>
    <van-cell-group v-for="(item,index) in myAgentList" :key="index" :title="'登记信息'+(myAgentList.length- index)">
      <van-cell title="联系人" :value="item.contactName"></van-cell>
      <van-cell title="电话" :value="item.phone"></van-cell>
      <van-cell title="公司名称" :value="item.companyName"></van-cell>
      <van-cell title="省/直辖市" :value="item.provinceName"></van-cell>
      <van-cell title="市" :value="item.cityName"></van-cell>
      <van-cell title="地址" :value="item.address"></van-cell>
      <van-cell title="审核状态">
        <van-tag v-if="item.isAudit" type="success">审核通过</van-tag>
        <van-tag v-else type="danger">审核中</van-tag>
      </van-cell>
    </van-cell-group>
    <van-empty v-if="myAgentList.length==0" description="无登记信息"></van-empty>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
Vue.use(Vant);

export default {
  data() {
    return { myAgentList: [] };
  },
  mounted() {
    let that = this;
    that.axios.post("Base_Agent/GetMyAgent").then(function (response) {
      that.myAgentList = response.data.data;
    });
  },
};
</script>

<style>
</style>